import React, { useEffect, useState } from "react";
import "./Nft.css";
import logo from "./logo2.gif";
import { Button } from "bootstrap";
import {mintforpublic, batchmintforpublic, GetChainId, ETHrecover, batchmintforadmin} from "./../../Web3/Web3"
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBadge,
  MDBInput,
  MDBCardSubTitle,
} from "mdb-react-ui-kit";
import { Box } from "@mui/system";
import {
  Grid,
  Typography,
} from "@mui/material";

const id = "63382cdc3171dfd5d7715948"
const notify = (msg) => toast.success(msg);
const warn = (msg) => toast.error(msg);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}


const rows = [
  createData('Frozen yoghurt'),
  createData('Ice cream sandwich'),
  createData('Eclair'),
  createData('Cupcake'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
  createData('Gingerbread'),
];


function Nft({url, account, contractadmin,}) {
  const [ids, setIDs] = useState()
  const [mintsingle, setMintsingle] = useState(0)
  const [showerror, setShowerror] = useState(false)
  const [showerroradmin, setShowerroradmin] = useState(false)
  const [mintedids ,setMintedids] = useState([])
  const [already, setAlready] = useState(0)
  const [adminids, setAdminIDs] = useState()

  useEffect(() => {
    const init = async () => {
      const id = await GetChainId();
      console.log("Chain ID is ",id);
      // if (Number(id) != 1) {
      //   await window.ethereum.request({
      //     method: "wallet_switchEthereumChain",
      //     params: [{ chainId: "0x1" }], // chainId must be in hexadecimal numbers
      //   });
      // }
    };
    init();
  }, []);
  
  useEffect(()=>{
    const init = async() =>{
      axios.get(`${url}/nfts`).then((res)=>{
        console.log(res)
        setMintedids(res.data[0].ids)
      })
    }
    init();
  },[])

  const mintDB = async()=>{
    mintedids.push(Number(mintsingle))
    axios.post(`${url}/addnft`,{
      id:id,
      nfts:mintedids
    }).then((res)=>{
      notify("Minted Successfully")
    }).catch((e)=>{
      console.log(e)
    })
  }
  
  const batchmintDB = async()=>{
    const mintid = ids.split(",")
    for(let i = 0; i < mintid.length; i++){
      mintedids.push(Number(mintid[i]))
    }
    axios.post(`${url}/addnft`,{
      id:id,
      nfts:mintedids
    }).then((res)=>{
      notify("Minted Successfully")
    }).catch((e)=>{
      console.log(e)
    })
  }

  const handleId =(e)=> {
    setMintsingle(e.target.value)
    const mintid = (e.target.value).split(",")
    if(mintid.length > 10){
      warn("Can not mint more than 10");
    }
    let allFounded = mintid.some( ai => mintedids.includes(Number(ai)));
    mintid.some( ai => setAlready(ai));
    if(allFounded){
      setShowerror(true)
    }
    else{
      setShowerror(false)
      setIDs(e.target.value)
    }
  }

  const batchmint =async()=>{
    let isrun = false
    isrun = await axios.get(url).then((res)=>{
      if(res.data){
        return res.data;
      }
      else{
        return false
      }
      
    })
  if(!isrun){
    warn("Something went wrong")
    return true
  }
    if(showerror){
      return true
    }
    const myArray = ids.split(",");
    // console.log(myArray)
    if(myArray.length > 10){
      warn("Can not mint more than 10 NFTs at once.")
      return true
    }
    const data = await batchmintforpublic(myArray);
    if(data.status){
      batchmintDB()
    }
  }

  const publicmint =async()=>{
    let isrun = false
    isrun = await axios.get(url).then((res)=>{
      if(res.data){
        return res.data;
      }
      else{
        return false
      }
      
    })
  if(!isrun){
    warn("Something went wrong")
    return true
  }
    if(showerror){
      return true
    }
    const data = await mintforpublic(mintsingle)
    if(data.status){
      mintDB()
    }
  }

  const reoverETH = async () => {
    const data = await ETHrecover();
    if (data.status) {
      notify("Success");
    }
  };

  const handleIdadmin =(e)=> {
    const mintid = (e.target.value).split(",")
    if(mintid.length > 10){
      warn("Can not mint more than 10");
    }
    let allFounded = mintid.some( ai => mintedids.includes(Number(ai)));
    if(allFounded){
      setShowerroradmin(true)
    }
    else{
      setShowerroradmin(false)
      setAdminIDs(e.target.value)
    }
  }

  const batchmintDBadmin = async()=>{
    const mintid = adminids.split(",")
    for(let i = 0; i < mintid.length; i++){
      mintedids.push(Number(mintid[i]))
    }
    axios.post(`${url}/addnft`,{
      id:id,
      nfts:mintedids
    }).then((res)=>{
      notify("Minted Successfully")
    }).catch((e)=>{
      console.log(e)
    })
  }

  const batchmintadmin =async()=>{
    let isrun = false
    isrun = await axios.get(url).then((res)=>{
      if(res.data){
        return res.data;
      }
      else{
        return false
      }
      
    })
    if(!isrun){
      warn("Something went wrong")
      return true
    }
    if(showerroradmin){
      return true
    }
    const myArray = adminids.split(",");
    const data = await batchmintforadmin(myArray);
    if(data.status){
      batchmintDBadmin();
    }
  }

  useEffect(()=>{
    setInterval(()=>{
      countdown()
    },1000)
  },[])

  const countdown = (tab, expire) => {
    const now = new Date().getTime()
    const end = 1667375520000
    var distance = end - now

    if(distance < 0){
      return "Time over"
    }

    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return days + "D " + hours + "H " + minutes + "M " + seconds + "S ";
  };

  return (
    <>
    <div>
        <marquee style={{color:'red'}}>Input NFT number you wish to mint. If you are minting multiple then put all the numbers separated by a comma.</marquee>
    </div>
    <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="reff-id"
          sx={{
            fontSize: "1rem",
            marginBottom: "3rem",
            textAlign: "center",
            fontWeight: 800,
            width: "100%",
            overflow: "hidden",
          }}
        >
          <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>Time left {countdown()}</Grid>
          </Grid>
        </Grid>
      <div className="row">
     
      <div className="col-lg-6 col-md-6 col-sm-12 col-12 Table">
      <TableContainer component={Paper} className="table table-minted">
      <Table sx={{ minWidth: 100 }} aria-label="simple table">
        <TableHead  className="minted" >
          <TableRow>
            <TableCell align="center" style={{fontWeight:"800", fontSize:"24px"}}>Minted NFTs</TableCell>
         
          </TableRow>
        </TableHead>
        <TableBody>
          {mintedids && mintedids.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row}
              </TableCell>
         
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-12">
      <div className="row">
        <div>
          <video loop autoPlay muted className="video">
            <source src={require("../../images/Video23.mp4")} type="video/mp4" />
          </video>
        </div>
        </div>
      
        <div>
          <input
            type="text"
            name="name"
            placeholder="1,10,100..."
            className="text23"
          
            onChange={(e)=>handleId(e)}
          />
          {/* {showerror ? <p className="waring">NFT #{ already } is allready minted</p> : ""} */}
        </div>
        <div>
          <button className="button1" onClick={()=>publicmint()}>Mint Single XAUS NFT</button>
        </div>
        <div>
          <button className="button2" onClick={()=>batchmint()}>Mint upto 10 XAUS NFT</button>
        </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 Table-mobile">
        <TableContainer component={Paper} className="table table-minted">
        <Table sx={{ minWidth: 100 }} aria-label="simple table">
          <TableHead  className="minted" >
            <TableRow>
              <TableCell align="center" style={{fontWeight:"800", fontSize:"14px"}}>Minted NFTs</TableCell>
           
            </TableRow>
          </TableHead>
          <TableBody>
            {mintedids && mintedids.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row}
                </TableCell>
           
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        </div>
    <div>
            {account == contractadmin ? <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} xl={6} style={{margin:"0 auto"}}>
              <MDBCard className="my-5 mx-3">
                <MDBCardBody>
                  <MDBCardTitle
                    className="text-center"
                    style={{ fontWeight: "bold" }}
                  >
               BATCH MINT
                  </MDBCardTitle>
                  <MDBCardSubTitle
                    className="text-center"
                    style={{
                      color: "#787373",
                      borderBottom: "1px solid #a5a0a0",
                    }}
                  ></MDBCardSubTitle>
                  {
                    <>
                    <Box style={{margin:"1rem 0",fontSize:"15px"}}>Input NFT number you wish to mint. If you are minting multiple then put all the numbers (upto 10 NFTs) separated by a comma.</Box>
                      <Box className="position-relative">
                        <Box className="title-area"></Box>
                        <MDBInput
                          id="form1"
                          onChange={(e) => handleIdadmin(e)}
                          type="text"
                          placeholder="Amount"
                          style={{ padding: "30px 20px" }}
                        />
                        {/* {showerroradmin ? <p className="waring">ID is allready minted</p> : ""} */}
                      </Box>
                    </>
                  }
                  <Box className="swap">
                    <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => batchmintadmin()}
                    >
                      MINT
                    </Typography>
                  </Box>
                  <Box className="swap">
                  <Typography
                      className="swap-button"
                      sx={{ margin: "auto" }}
                      onClick={() => reoverETH()}
                    >
                      RECOVER ETH
                  </Typography>
                  </Box>
                </MDBCardBody>
              </MDBCard>
            </Grid>
            </Grid> : ''}
    </div>
      </div>
      <Toaster/>
    </>
  );
}

export default Nft;
