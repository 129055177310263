import React, { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBCardImage,
} from "mdb-react-ui-kit";
import meta from "../images/meta.png";
import { GrLogout } from "react-icons/gr";
import { MdArrowDropDown } from "react-icons/md";
import connect from "../images/connect.svg";
import logo from "../images/e2bc39a2d59c627c24ff83406d75d1a6.png";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { getAdmin, GetChainId, webProvide} from "../Web3/Web3";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Button, Modal } from "react-bootstrap";

export default function App({ Metamask, account, contractadmin, Dissconnect, walletConnectlogin }) {
  let web3 = webProvide()
  const [showBasic, setShowBasic] = useState(false);
  const [active, setActive] = useState(1);
  const [network, setNetwork] = useState();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log("Admins", account, contractadmin)
  useEffect(() => {
    const init = async () => {
      const id = await GetChainId();
      console.log("Chain ID is ", id);
      if (id == 56) {
        setNetwork("Binance Smart Chain");
      } else if (id == 5) {
        setNetwork("Goerli Testnet");
      } else if (id == 1) {
        setNetwork("Ethereum");
      } else {
        setNetwork(undefined);
      }
    };
    init();
  }, [account]);

  const slicewallet = (add) => {
    const first = add.slice(0, 5);
    const second = add.slice(37);
    return first + "..." + second;
  };

  const switchnetwork = async (id) => {
    await web3.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${id}` }], // chainId must be in hexadecimal numbers
    });
  };

  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <MDBNavbar expand="lg" light bgColor="transparent">
      <MDBContainer fluid>
        <MDBNavbarBrand href="#">
          <img src={logo} style={{ width: "90px" }} />
        </MDBNavbarBrand>

        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 justify-content-center">
            {/* <MDBNavbarItem onClick={() => setActive(1)}>
              <MDBNavbarLink
                aria-current="page"
                href="/"
                className={window.location.pathname == "/" ? "active" : ""}
              >
                HOME
              </MDBNavbarLink>
            </MDBNavbarItem> */}
            <>
              <Link
                id="link"
                onClick={() => setActive(1)}
                to="/"
                className={window.location.pathname === "/" ? "active" : ""}
              >
                HOME
              </Link>
              <Link
                id="link"
                onClick={() => setActive(3)}
                to="/staking"
                className={
                  window.location.pathname === "/staking" ? "active" : ""
                }
              >
                STAKING
              </Link>
              {/* <Link
                id="link"
                onClick={() => setActive(4)}
                to="/nft"
                className={window.location.pathname === "/nft" ? "active" : ""}
              >
                NFT
              </Link> */}
              {(account && account == contractadmin) ||
              account == "0xD9E0f712652589584B035Db1cb44A79F2eA2389F" ||
              account == "0x149b65e2EB31c196F9C2407E0A88a9cF1F71bd35" ||
              account == "0xdBd21416Da1207Bfb66BDf3baBE16538f112b706" ||
              account == "0x999619733a2df505229967Db54b84D6F4709DC45" ||
              account == "0x8A25ea29363894eAcf4f0ef1Bfe0cAfc75a224FF" ||
              account == "0x9929BbE55e79cAC1003Dc4c9cD2e911CbaAd532D" ? (
                <>
                  <Link
                    onClick={() => setActive(2)}
                    id="link"
                    to="/admin"
                    className={
                      window.location.pathname === "/admin" ? "active" : ""
                    }
                  >
                    ADMIN
                  </Link>
                </>
              ) : (
                ""
              )}
            </>
          </MDBNavbarNav>
          {/* <button
            type="button"
            className="connectButton mx-3"
            onClick={() => {
              if (account) {
                Dissconnect();
                return true;
              }
              console.log("Running");
              Metamask();
            }}
          >
            {account ? slicewallet(account) : "Connect Wallet"}
          </button> */}
          <Button
            className="connectButton2 mx-3"
            variant="primary"
            onClick={() => {
              if (account) {
                Dissconnect();
                return true;
              }
              handleShow();
            }}
          >
            {account ? slicewallet(account) : "Connect Wallet"}
          </Button>
          <button
            className="connectButton mx-3"
            type="button"
            style={{ marginRight: "1rem" }}
          >
            BUY GCS
          </button>

          <div className="dropdown mx-3">
            <button
              className="dropdown-toggle "
              type="button"
              id="dropdownMenuButton"
              data-mdb-toggle="dropdown"
              aria-expanded="false"
            >
              {network ? network : "NETWORKS"}
            </button>
            <ul
              className="dropdown-menu disconnect"
              aria-labelledby="dropdownMenuButton"
            >
              <li className="network" onClick={() => switchnetwork(1)}>
                Ethereum
              </li>
              <li className="network" onClick={() => switchnetwork(38)}>
                Binance Smart Chain
              </li>
              <li className="network" onClick={() => switchnetwork(5)}>
                Goerli Testnet
              </li>
            </ul>
          </div>
        </MDBCollapse>
      </MDBContainer>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4 className="text-center">Please connect your wallet</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center justify-content-around">
            <img
              src={meta}
              alt=""
              className="wallet-img"
              onClick={() => {
                Metamask()
                setShow(!show)
              }}
            
            />
            <img
              src={connect}
              alt=""
              className="wallet-img2"
              onClick={() => {
                walletConnectlogin();
                setShow(!show)
              }}
            />
          </div>
        </Modal.Body>
      </Modal>
    </MDBNavbar>
  );
}
