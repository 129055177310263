import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./compnents/Navbar";
import * as React from "react";
import { useEffect, useState } from "react";
import {
  XAUs_Totak_Supply,
  USDM_Totak_Supply,
  GCS_Totak_Supply,
  login,
  GetChainId,
  getUserAddress,
  getAdmin,
  DissconnectWallet,
  getAdminNFT,
  webProvide,
  WalletConnectlogin,
} from "./Web3/Web3";
import axios from "axios";
import Footer from "./compnents/Footer";
import Admin from "./compnents/pages/Admin";
import Home from "./compnents/pages/Home";
import "./App.css";
import CreateLevel from "./compnents/pages/CreateLevel.tsx";
import Referralid from "./compnents/pages/Referralid.tsx";
import Secondlevel from "./compnents/pages/Secondlevel.tsx";
import StakingTable from "./compnents/StakingTable";
import Staking from "./compnents/pages/StakingNav.tsx";
import Nft from "./compnents/pages/Nft.js";
import PersonalReferralId from "./compnents/pages/PersonalReferralId.tsx";
import Pool from "./compnents/pages/Addpool";
import SeeLevel from "./compnents/pages/SeeLevel";
import Stakingrefferal from "./compnents/pages/Stakingrefferal";
const url = "https://apigctech.ap.ngrok.io";
const url2 = "https://refer.ap.ngrok.io";
// const url2 = "http://localhost:3030";

function App() {
  const [xausSupply, setXausSupply] = useState(0);
  const [usdmSupply, setUsdmSupply] = useState(0);
  const [gcsSupply, setGcsSupply] = useState(0);
  const [contractadmin, setContractAdmin] = useState();
  const [nftadmin, setNFTadmin] = useState("");
  const web3 = webProvide();

  useEffect(() => {
    const init = async () => {
      const xaus = await XAUs_Totak_Supply();
      setXausSupply(xaus);
      const gcs = await GCS_Totak_Supply();
      setGcsSupply(gcs);
      const usdm = await USDM_Totak_Supply();
      setUsdmSupply(usdm);
      const user = await getAdmin();
      // console.log("Staking admin",user)
      setContractAdmin(user);
      const adminnft = await getAdminNFT();
      setNFTadmin(adminnft);
      // console.log("nft admin",adminnft)

      const closeprice = await axios
        .get("https://close.ap.ngrok.io/kws/v4/closeprice", {})
        .then(function (response) {
          return Number(response.data[0].GCStoUSDT);
        })
        .catch(function (error) {
          console.log(error);
        });

      const mmk = await axios
        .get(`https://apigctech.ap.ngrok.io/mmkprice`)
        .then(function (response) {
          response.data.reverse();
          // console.log(response.data[0].price);
          return response.data[0].price;
        })
        .catch(function (error) {
          console.log("Error", error);
        });

      const govt = await axios
        .get(`https://apigctech.ap.ngrok.io/values`)
        .then(function (response) {
          return response.data[0].govt;
        })
        .catch(function (error) {
          console.log(error);
        });

      let headersXau = {
        "x-access-token": "goldapi-18s8tl8b4ov8m-io",
        "Content-Type": "application/json",
      };

      let xauOptions = {
        url: "https://www.goldapi.io/api/XAU/USD",
        method: "GET",
        headers: headersXau,
      };
      const xau = await axios.request(xauOptions).then(function (response) {
        // console.log("API gold", response)
        return response.data.price;
      });

      let headersList = {
        Accept: "*/*",
        "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      };
      let reqOptions = {
        url: "https://api.exchangerate.host/convert?from=USD&to=CNH",
        method: "GET",
        headers: headersList,
      };
      const btwo = await axios.request(reqOptions).then(function (response) {
        return Number(response.data.info.rate).toFixed(5);
      });

      const added = await axios
        .get(`${url}/values`)
        .then(function (response) {
          return response.data[0].addMMk;
        })
        .catch(function (error) {
          console.log(error);
        });

      Calculation(xaus, gcs, usdm, closeprice, mmk, govt, xau, btwo, added);
    };
    init();

    setInterval(() => {
      init();
    }, 10000);
  }, []);

  const [gcsmaketcap, setGcsSupplyCap] = useState(0);
  const [gcstousd, setGcstoUsd] = useState(0);
  const [gcsusdm, setGcsusdm] = useState(0);
  const [xaustousd, setXaustoUsd] = useState(0);
  const [xausmk, setXausmk] = useState(0);
  const [usdmtousdt, setUsdmtousdt] = useState(0);
  const [usdmmarketcap, setUsdmMarketcap] = useState(0);
  const [xaustogcs, setXausGcs] = useState(0);
  const [xaustousdm, setXaustousdm] = useState(0);

  const Calculation = (
    xaus,
    gcs,
    usdm,
    closeprice,
    mmk,
    govt,
    xau,
    btwo,
    added
  ) => {
    const gcsmk = (Number(closeprice) * 5000000).toFixed(0);
    setGcsSupplyCap(gcsmk);
    const gcstousd = closeprice;
    setGcstoUsd(closeprice);
    const gcsusdm = ((mmk + added) * closeprice) / govt;
    setGcsusdm(gcsusdm);
    const xaustousd = ((xau / 31.1025) * 0.425 * 1.03).toFixed(5);
    setXaustoUsd(xaustousd);
    const xausmk = Number(
      xaus * ((xau / 31.1025) * 0.425 * 1.03).toFixed(5)
    ).toFixed(0);
    setXausmk(xausmk);
    const usdmtousdt = Number(closeprice / gcsusdm).toFixed(5);
    setUsdmtousdt(usdmtousdt);
    const usdmmk = 755030 * closeprice;
    setUsdmMarketcap(usdmmk);
    const xaustogcs = (((xau / 31.1025) * 0.425 * 1.03) / gcstousd).toFixed(5);
    setXausGcs(xaustogcs);
    const xaustousdm = Number(
      ((xau / 31.1025) * 0.425 * 1.03) / (3 / btwo)
    ).toFixed(5);
    setXaustousdm(xaustousdm);
  };

  const [acount, setAccount] = useState();

  useEffect(() => {
    const init = async () => {
      const wallet = window.localStorage.getItem("wallet");
      if (wallet) {
        Metamask();
      }
      const id = await GetChainId();
      console.log("Chain ID is ", id);
    };

    init();
  }, [acount]);

  try {
    // console.log("webProvide",web3, window.ethereum)
  } catch (error) {}
  const walletConnectlogin = async () => {
    await WalletConnectlogin();
    const add = await getUserAddress();
    setAccount(add);
  };

  const Metamask = async () => {
    await login();
    const add = await getUserAddress();
    setAccount(add);
    window.localStorage.setItem("wallet", "Metamask");
  };

  const Dissconnect = async () => {
    await DissconnectWallet();
    setAccount(undefined);
    window.localStorage.removeItem("wallet");
  };

  try {
    web3.on("accountsChanged", (accounts) => {
      setAccount(accounts[0]);
    });
  } catch (error) {
    console.log(error);
  }

  try {
    web3.on("chainChanged", function (accounts) {
      window.location.reload();
    });
  } catch (error) {
    // console.log(error)
  }
  //  try {
  //   web3.on("session_update", (error, payload) => {
  //     if (error) {
  //       throw error;
  //     }
  //     const { accounts, chainId } = payload.params[0];
  //     console.log(accounts, chainId)
  //   });
  //  } catch (error) {
  //   console.log(error)
  //  }

  return (
    <div className="App">
      <Router>
        <Navbar
          Metamask={Metamask}
          walletConnectlogin={walletConnectlogin}
          account={acount}
          contractadmin={contractadmin}
          Dissconnect={Dissconnect}
        />

        <Routes>
          <Route
            exact
            path="/"
            element={
              <Home
                gcsmaketcap={gcsmaketcap}
                gcstousd={gcstousd}
                gcsusdm={gcsusdm}
                xaustousd={xaustousd}
                xausmk={xausmk}
                usdmtousdt={usdmtousdt}
                usdmmarketcap={usdmmarketcap}
                xaustogcs={xaustogcs}
                xaustousdm={xaustousdm}
                account={acount}
                url={url}
              />
            }
          />
          <Route
            path="/admin"
            element={
              <Admin
                account={acount}
                contractadmin={contractadmin}
                url={url2}
              />
            }
          />
          {/* <Route
            path="/nft"
            element={<Nft url={url2} account={acount} contractadmin={nftadmin} />}
          /> */}
          <Route
            path="/staking"
            element={<Staking account={acount} url={url2} />}
          />
          <Route
            path="/staking/:ID"
            element={<Staking account={acount} url={url2} />}
          />
          <Route
            path="/admin/referral-second/:secondref"
            element={<Secondlevel account={acount} url={url2} />}
          />
          <Route
            path="/staking/myrefferals/:user"
            element={<Stakingrefferal account={acount} url={url2}/>}
          />
          <Route
            path="/admin/staker's-detail"
            element={<StakingTable account={acount} url={url2} />}
          />
          <Route
            path="/admin/pool"
            element={<Pool account={acount} url={url2} />}
          />
          <Route
            exact
            path="/admin/referral-id/:ref/:poodid/:lptoken/:reward"
            element={<Referralid account={acount} url={url2} />}
          />
          <Route
            exact
            path="/admin/000xxxx2323245"
            element={<PersonalReferralId />}
          />
          <Route
            exact
            path="/admin/create-level"
            element={<CreateLevel account={acount} url={url2} />}
          />
          <Route
            exact
            path="/admin/see-level"
            element={<SeeLevel url={url2} />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
